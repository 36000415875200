import React, { useEffect, useState } from 'react'

const SuccessHandler = () => {
  const redirect = window.sessionStorage.getItem('callbackurl')

  window.sessionStorage.removeItem('token')

  if (redirect) {
    setTimeout(() => {
      window.sessionStorage.removeItem('callbackurl')
      window.location.href = `${redirect}`
    }, 3000)
  }

  return (
    <>
      <h1>
        Tudo <span>pronto!</span>
      </h1>
      <hr />
      <p className="text-center">
      Seu cadastro facial foi realizado com sucesso <br/>
      </p>
    </>
  )
}

export default SuccessHandler
