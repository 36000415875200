import React, { Component, useCallback, useEffect, useState } from 'react'
import './UserSignup.sass'
import { BASE } from './../basedata/constants'

import { useForm } from 'react-hook-form'
import LoadingHandler from '../Biometric/StatesComponents/LoadingHandler'
import { Card } from 'react-bootstrap'

interface Props {
  onResponse: (response: any) => void
}

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

function UserSignup({ onResponse }: Props) {
  const [currentStep, setCurrentStep] = useState(1)

  const [accessCode, setAccessCode] = useState('')

  const [registerError, setregisterError] = useState({
    status: false,
    message: ''
  })

  const [formData, setformData] = useState({
    fullname: ''
  })

  function VerifyUser() {
    const {
      register,
      handleSubmit,
      setError,
      formState: { errors },
      clearErrors
    } = useForm()

    const onSubmit = (data: any) => {

      // setformData({
      //   fullname: data.fullname
      // })

      setCurrentStep(3)

      fetch(`${BASE.api.base_url}${BASE.api.user_validate}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
          document: data.document
        })
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 'success') {
            const currentToken = response.token
            sessionStorage.setItem('token', currentToken)
            sessionStorage.setItem('originCompany', 'd5c19737-7e4e-4dd8-9931-d6bc3bc961d1')

            return onResponse({
              token: currentToken,
              step: 'faceinformation'
            })
          }

          if (response.status === 'userRegistered') {
            setCurrentStep(1)
            return setregisterError({
              status: true,
              message: 'Já temos uma face para esse convite :)'
            })
          }

          if (response.status === 'userRegisteredButBiometric') {
            const currentToken = response.token
            sessionStorage.setItem('token', currentToken)
            sessionStorage.setItem('originCompany', 'd5c19737-7e4e-4dd8-9931-d6bc3bc961d1')
           
            return onResponse({
              token: currentToken,
              step: 'faceinformation'
            })
          }

          setregisterError({
            status: true,
            message: 'Não encontramos um convite válido para o CPF informado.'
          })
          return setCurrentStep(4)
          // if (response.token) {
          //   sessionStorage.setItem('token', response.token)

          //   return onResponse({
          //     token: response.token,
          //     step: 'faceinformation'
          //   })
          // }
          // return setCurrentStep(2)
        })
        .catch((error) => {
          // if (error) {
            // }
            // setCurrentStep(1)
            setregisterError({
              status: true,
              message: 'Não encontramos um convite válido para o CPF informado.'
            })
            return setCurrentStep(4)
        })

      // return onResponse('faceinformation')
    }
    
    if (currentStep !== 1) {
      return null
    }

    return (
      <>
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <h1>
            Seja <span>bem-vindo!</span>
          </h1>
          <p className='description-info'>
          Por favor, informe seu cpf para prosseguir
          </p>
          <div className="form-wrapper">
            <div className="form-floating">
              <input
                placeholder="Nome completo"
                className="form-control form-control-lg"
                {...register('document', { required: true })}
              />
              <label className="form-label" htmlFor="document">
                Seu CPF
              </label>
              {errors.document && (
                <span className="label-error">
                  Você precisa informar seu CPF
                </span>
              )}
            </div>
            {registerError.status && (
                <span className="label-error">{registerError.message}</span>
              )}
          </div>
          <p className='new-text-mtfk'>
          Eu autorizo que o Bradesco, por meio de seus operadores, realize o tratamento da minha biometria facial para a finalidade exclusiva do controle de acesso ao evento POBJ2023. Para saber mais como o Bradesco tratará seus dados pessoais, acesse nosso aviso de privacidade. Todos os dados pessoais e sensíveis tratados para a finalidade de acesso ao evento POBJ2023, serão eliminados de forma irrecuperável, imediatamente, após o término do evento.
          </p>

          <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input form-control-sm"
                {...register('valid', {required: true})}
                id="valid"
              // defaultChecked
              />
              <label className="form-check-label" htmlFor="valid">
                Eu aceito os{' '}
                <a href="/aviso_privacidade_bradesco.pdf" rel="noreferrer" target="_blank">
                  termos de uso e politica de privacidade
                </a>{' '}
                para o uso BePass
              </label>
              {errors.valid && (
                <span className="label-error">
                  Você precisa aceitar os termos de uso
                </span>
              )}
            </div>
          <div className="form-group form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Próximo"
              onClick={() => clearErrors()}
            />
          </div>
          {/* <a className="help-link" href="#">
          Ajuda
        </a> */}
        </form>
        <ul className="step-counter">
          <li className="active"></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    )
  }

  function Step2() {
    const {
      register,
      handleSubmit,
      setError,
      reset,
      formState: { errors },
      clearErrors
    } = useForm()

    const onSubmit = (data: any) => {

      if (!isValidEmail(data.email)) {
        setError('email', {
          type: 'manual',
          message: 'Você precisa informar um E-mail válido'
        })
        return false
      }

      if (data.celphone.length < 12) {
        setError('celphone', {
          type: 'manual',
          message: 'Você precisa informar um telefone válido'
        })
        return false
      }

      let currentFormData = data

      currentFormData = {
        ...formData,
        ...currentFormData,
        token: accessCode
      }

      setCurrentStep(3)

      fetch(`${BASE.api.base_url}${BASE.api.user_create}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
          firstName: currentFormData.fullname
            .replace(/^\s+|\s+$/g, '')
            .split(' ')[0],
          externalId: currentFormData.externalId,
          lastName: currentFormData.fullname.replace(/^\s+|\s+$/g, ''),
          gender: '',
          document: currentFormData.document.replace(/^\s+|\s+$/g, ''),
          document_type: '',
          email: currentFormData.email.replace(/^\s+|\s+$/g, ''),
          phoneNumber: currentFormData.celphone
            .replace(/"/g, '')
            .replace(/'/g, '')
            .replace(/\(|\)/g, ''),
          nationality: '',
          token: `${accessCode}`,
          originCompanyId: 'd5c19737-7e4e-4dd8-9931-d6bc3bc961d1'
        })
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.onboarding_url) {
            const currentToken = response.onboarding_url.split('token=')[1]
            sessionStorage.setItem('token', currentToken)
            sessionStorage.setItem('originCompany', 'd5c19737-7e4e-4dd8-9931-d6bc3bc961d1')

            return onResponse({
              token: currentToken,
              step: 'faceinformation'
            })
          }
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    if (currentStep !== 2) {
      return null
    }

    // useEffect(() => {
    //   setTimeout(() => {
    //     reset({ name: formData.fullname })
    //   }, 3000)
    // }, [formData.fullname])

    return (
      <>
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <p>
            Certo! Agora precisamos que você informe alguns dados para
            validarmos seu cadastro.
          </p>
          <div className="form-wrapper">
            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Nome"
                {...(register('name'), { disabled: true })}
                defaultValue={formData.fullname}
              />
              <label className="form-label" htmlFor="name">
                Nome completo
              </label>
              {errors.name && (
                <span className="label-error">
                  Você precisa informar um Nome válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="CPF"
                {...register('document',  { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskCPF(value)
                }}
              />
              <label className="form-label" htmlFor="document">
                CPF
              </label>
              {errors.document && (
                <span className="label-error">
                  Você precisa informar um Documento válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="E-mail"
                {...register('email',  { required: true })}
              />
              <label className="form-label" htmlFor="email">
                E-mail
              </label>
              {errors.email && (
                <span className="label-error">
                  Você precisa informar um E-mail válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Celular"
                {...register('celphone', { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskPhone(value)
                }}
              />
              <label className="form-label" htmlFor="celphone">
                Celular
              </label>
              {errors.celphone && (
                <span className="label-error">
                  Você precisa informar um telefone válido
                </span>
              )}
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input form-control-sm"
                {...register('valid')}
                id="valid"
                checked
              // defaultChecked
              />
              <label className="form-check-label" htmlFor="valid">
                Eu aceito os{' '}
                <a href="/bepass_terms.pdf" rel="noreferrer" target="_blank">
                  termos de uso e politica de privacidade
                </a>{' '}
                para o uso BePass
              </label>
              {/* {errors.celphone && (
                <span className="label-error">
                  Você precisa aceitar os termos de uso
                </span>
              )} */}
            </div>
          </div>

          <div className="form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Enviar"
              onClick={() => clearErrors()}
            />
          </div>
        </form>

        <ul className="step-counter">
          <li className="active"></li>
          <li className="active"></li>
          <li></li>
          <li></li>
        </ul>
      </>
    )
  }


  function StepInfo4() {

    if (currentStep !== 4) {
      return null
    }


    return (
      <>
        <Card>
        <h1>
            Ooops! <span>Atenção!</span>
          </h1>
          <p>
          Não identiifcamos este CPF em nossa lista de convidados.
          </p>

          <div className='form-wrapper'>
          <p className='description-info'>
    
          Por favor, retorne à página anterior e certifique-se que o CPF informado está digitado corretamente.<br/><br/>
          Caso tenha dificuldade, entre em contato conosco pelo WhatsApp (11)9644.20594 ou (11) 3067.0875<br/><br/>
          Contamos com sua compreensão e estamos ansiosos para recebê-lo no evento de premiação do Bradesco.
               </p>
          </div>

          <div className="form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Voltar"
              onClick={() => setCurrentStep(1)}
            />
          </div>
        </Card>

        <ul className="step-counter">
          <li className="active"></li>
          <li className="active"></li>
          <li></li>
          <li></li>
        </ul>
      </>
    )
  }

  return (
    <>
      {/* Verify if users exists */}
      <VerifyUser />
      {/* Sign user up */}
      <Step2 />

      <StepInfo4/>
      {/* Log user in */}
      {currentStep === 3 && <LoadingHandler />}
    </>
  )
}

export default UserSignup
